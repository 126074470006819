<template>
  <div class="manifest_table_out_div">
    <AllManifestSearchBar  id="ManifestSearchBar" @edit="edit" @search="searchList" :multiple-selection="multipleSelection"
                          :type="search_completionstatus"></AllManifestSearchBar>
    <ShManifestTable
        :type="search_completionstatus"
        :curr-page="currPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :total-count="totalCount"
        :table-header="tableHeaders"
        :table-data="tableData"
        :data-loading="dataLoading"
        @fromChild="getSelection"
        @refresh="getList"
        @edit="edit"
        @nextPage="nextPage"
        @pageSizeChange="pageSizeChange"
        ref="ShManifestTable">
    </ShManifestTable>
  </div>
</template>

<script>
import mixin from '@/mixin/manifest/sh/manifestTable'
import AllManifestSearchBar from "@/views/ShanghaiManifest/AllManifest/AllManifestSearchBar.vue";
import {getAllUserManifestList, queryShowList} from "@/api/shanghaiManifest";
import bus from "@/utils/bus";

export default {
  mixins: [mixin],
  components: {AllManifestSearchBar},
  name: "AllManifestTable",
  data() {
    return {
      search_completionstatus: 3, //系统管理员查看所有企业舱单
      selectCompletionStatus: '',
    }
  },
  methods:{
    init() {
      this.resetTableHeaders();
      this.loginUserId = '';
      this.search.showUserId = this.loginUserId;
      this.getList();
    }, //初始化界面
    getList() {
      let data = {
        ...this.search,
        id: this.$store.state.user.userId,
        completionstatus: this.selectCompletionStatus,
        page: this.currPage,
        limit: this.pageSize,
      }
      // this.$nextTick(() => {
      //   this.$refs.ShManifestTable.init(this.tableData, this.tableHeaders, 0)
      // })
      this.dataLoading = true;
      getAllUserManifestList(data)
          .then((response) => {
            if (response.data.page) {
              this.totalPage = response.data.page.totalPage;
              this.totalCount = response.data.page.totalCount;
              this.currPage = response.data.page.currPage;
              this.pageSize = response.data.page.pageSize;
              this.tableData = response.data.page.list.map((item) => {
                item.vesselvoyage = item.vesselname + ' / ' + item.voyageno;
                item.webtype = item.webtype.split('|')[0];
                item.total = item.totalpackno + ' / ' + item.totalweight + ' / ' + item.totalvolume;
                return item
              });
            }
          }).finally(() => {
        this.dataLoading = false;
      })

      // bus.$emit("getCountNum");
    },
  }
}
</script>

<style lang="stylus" scoped>

</style>
